/* eslint-disable react-hooks/exhaustive-deps */
import { AlertResponse, Feature, FeatureType, Group, PersonType, ThingType } from '@eagle/core-data-types';
import {
  ALERT_LIST_ALERT_TYPE_FILTER_V2_FLAG,
  alertFilterToQuery,
  AlertListPage,
  AlertsQuery,
  AlertsTableView,
  AppliedFilter,
  AppliedFilterType,
  CacheDataTypes,
  DateFilterField,
  entityGroup,
  EntityType,
  ErrorPage,
  FeatureIcons,
  featureInstancesToFilterEntities,
  FilterDataTypes,
  filterDeletedCache,
  FilterFieldNewProps,
  FilterPathIdentifiers,
  filterToQuery,
  FilterTypes,
  FindItemsDeferredResult,
  FormatTimestamp,
  getAlertTypeFilters,
  getEventLabel,
  getEventLabelV2,
  getListResultDescription,
  MiddleSpinner,
  NEW_ALERT_FILTER_FLAG,
  NEW_FILTER_FLAG,
  NewFilter,
  Pagination,
  PortalFeatureIcons,
  RangeSelectValues,
  SAVED_FILTER_KEY,
  T_MANY,
  trackEvent,
  useAuthenticated,
  useBoolFlag,
  useDynamicModule,
  useFetchAllCache,
  useMyAlertableFeaturesQuery,
  usePromise,
  useSmallScreen
} from '@eagle/react-common';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AlertList: FC = () => {
  const { t } = useTranslation(['common', 'terms', 'track']);
  const { restClient } = useAuthenticated();
  const smallScreen = useSmallScreen();
  const [refresh, setRefresh] = useState(false);
  const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
  const useDateTimeRangeSelectComponent = useBoolFlag('track-alerts-list-datetime-picker-component-temporary');
  const initialStartTime = useDateTimeRangeSelectComponent
    ? DateTime.now().startOf('day')
    : DateTime.now().minus({ hours: 8 });
  const initialEndTime = DateTime.now();
  const enableNewAlertFilter = useBoolFlag(NEW_ALERT_FILTER_FLAG);
  const useNewFilter = useBoolFlag(NEW_FILTER_FLAG);
  const useEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const isAlertTypeFilterV2Enabled = useBoolFlag(ALERT_LIST_ALERT_TYPE_FILTER_V2_FLAG);

  const { data: alertableFeatures } = useMyAlertableFeaturesQuery(null, isAlertTypeFilterV2Enabled);

  const groupCache = useFetchAllCache(CacheDataTypes.GROUP);
  const [groups, groupsError, groupsState] = usePromise(
    filterDeletedCache<Group>(groupCache),
    [groupCache],
  );

  const personTypesCache = useFetchAllCache(CacheDataTypes.PERSON_TYPE);
  const [personTypes, personTypesError, personTypesState] = usePromise(
    filterDeletedCache<PersonType>(personTypesCache),
    [personTypesCache],
  );

  const thingTypesCache = useFetchAllCache(CacheDataTypes.THING_TYPE);
  const [thingTypes, thingTypesError, thingTypesState] = usePromise(
    filterDeletedCache<ThingType>(thingTypesCache),
    [thingTypesCache],
  );

  const myFeatureCache = useFetchAllCache(CacheDataTypes.MY_FEATURE);
  const featureTypeCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);
  const [myFeatures, myFeaturesError, myFeaturesState] = usePromise<Feature[]>(
    myFeatureCache.all(),
    [myFeatureCache],
  );
  const [featureTypes, featuresError, featuresState] = usePromise<FeatureType[]>(
    featureTypeCache.all(),
    [featureTypeCache],
  );

  const dateOptions: DateFilterField[] = useMemo(() => ([
    {
      id: '0',
      data: {
        endTime: (time) => time,
        startTime: (time) => time.minus({ hours: 8 }),
      },
      display: t('common:component.date-time-range.labels.last-hour', { count: 8 }),
    },
    {
      id: '1',
      data: {
        endTime: (time) => time,
        startTime: (time) => time.startOf('day'),
      },
      display: t('common:component.date-time-range.labels.today'),
    },
    {
      id: '2',
      data: {
        endTime: (time) => time.startOf('day'),
        startTime: (time) => time.minus({ days: 1 }).startOf('day'),
      },
      display: t('common:component.date-time-range.labels.yesterday'),
    },
  ]), [t]);

  const formatFilter = useCallback((_id: string, event: string, customKey?: string): EntityType => ({
    _id,
    display: t(customKey ? customKey : useEventV3 ? getEventLabel('event-record', _id) : getEventLabelV2(event, _id, 'event-record')),
    properties: {
      definitions: {},
      order: [],
    },
  }), [getEventLabel, getEventLabelV2, t, useEventV3]);

  const alertTypes = useMemo(
    () => {
      if (isAlertTypeFilterV2Enabled) {
        if (!alertableFeatures || !myFeatures) return [];
        return featureInstancesToFilterEntities(alertableFeatures, myFeatures);
      }

      return getAlertTypeFilters(featureTypes, myFeatures, formatFilter, enableNewAlertFilter, useEventV3);
    },
    [featureTypes, myFeatures, formatFilter, enableNewAlertFilter, useEventV3, alertableFeatures, isAlertTypeFilterV2Enabled]
  );

  const actions = useMemo(() => ([
    {
      icon: <RefreshIcon />,
      info: <Typography data-chromatic="ignore" variant="caption">{`${t('common:page.alert-list.last-updated.labels')} `}<FormatTimestamp value={lastRefresh} format="relative" /></Typography>,
      label: t('common:common.action.refresh'),
      onClick: () => {
        setRefresh((prev) => !prev);
      },
      testId: 'action-button-refresh',
    },
  ]), [lastRefresh]);

  const findAlerts = useCallback(({ filters, pagination, search, dateRange, savedSelection }: AlertsQuery): FindItemsDeferredResult<AlertResponse> => {
    const cancelToken = Axios.CancelToken.source();
    let dateRangeStart = dateRange?.startTime.toUTC().toISO() ?? initialStartTime.toUTC().toISO();
    let dateRangeFinish = dateRange?.endTime.toUTC().toISO() ?? initialEndTime.toUTC().toISO();

    if (!useDateTimeRangeSelectComponent) {
      if (!smallScreen && savedSelection !== RangeSelectValues.PICK_CUSTOM_RANGE.key) {
        for (const [key] of Object.entries(RangeSelectValues)) {
          if (RangeSelectValues[key].key === savedSelection) {
            dateRangeStart = DateTime.now().minus({ hours: RangeSelectValues[key].hours }).toUTC().toISO();
            dateRangeFinish = initialEndTime.toUTC().toISO();
          }
        }
      }
    }

    const alertThingFilters = filters.alerts.filter(({ pathIdentifier }) => pathIdentifier === FilterPathIdentifiers.THING);
    const alertGroupFilters = filters.alerts.filter(({ pathIdentifier }) => pathIdentifier === FilterPathIdentifiers.GROUP);
    const alertPersonFilters = filters.alerts.filter(({ pathIdentifier }) => pathIdentifier === FilterPathIdentifiers.PERSON);

    const alertFilters = filters.alerts.filter(({ pathIdentifier }) => !pathIdentifier);
    const thingFilters = [...filters.things, ...alertThingFilters, ...alertGroupFilters];
    const personFilters = [...filters.persons, ...alertPersonFilters];

    return {
      cancel: cancelToken.cancel,
      promise: restClient.alert.getAllV2({
        ...pagination,
        dateRangeStart: new Date(dateRangeStart),
        dateRangeFinish: new Date(dateRangeFinish),
        filter: enableNewAlertFilter ? alertFilterToQuery(alertFilters) : filterToQuery(alertFilters),
        ...(search ? { search } : {}),
        sort: '-occurred',
        thingFilter: filterToQuery(thingFilters),
        personFilter: filterToQuery(personFilters),
      }, { cancelToken: cancelToken.token }).then((response) => {
        setLastRefresh(new Date());
        const filters = [...alertFilters, ...thingFilters, ...personFilters];
        const matchCount = response.count ?? 0;
        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'common:terms:alert', filters, search, t });

        if (search) {
          trackEvent('keyword_search', 'returned_results', 'alert_list', { 'results_type': 'alert', 'results_count': matchCount, 'search_term': search });
        }

        return {
          result: {
            results: response.items,
            itemCount: matchCount,
          },
          resultDescription,
        };
      }),
    };
  }, [smallScreen, restClient, enableNewAlertFilter]);

  const renderContent = useCallback((
    alerts: AlertResponse[],
    isLoading: boolean,
    matchCount: number,
    pagination: Pagination,
    setPagination: (value: Pagination) => void,
  ) => {
    return (
      <AlertsTableView
        alerts={alerts}
        isLoading={isLoading}
        matchCount={matchCount}
        pagination={pagination}
        setPagination={setPagination}
        showGroups={true}
        showPerson={true}
        showThing={true}
        showFeature={false}
        stickyHeader={false}
        handleShowMore={() => { }}
        isRecentAlertsView={false}
      />
    );
  }, []);

  const renderFilterContent = useCallback((
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ): JSX.Element => {
    const filterFields: FilterFieldNewProps[] = [
      {
        dataType: FilterDataTypes.ALERT,
        entityTypes: alertTypes ?? [],
        fieldLabel: t('common:component.filter.labels.select-an-alert-type'),
        propertyLabel: t('common:terms.alert-type', { count: T_MANY }),
        typePropertyName: FilterTypes.ALERT_TYPE,
      },
      {
        apiUrl: '/api/v1/person',
        attributes: {
          typeCache: personTypesCache,
          typePath: FilterTypes.PERSON_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.select-a-person'),
        pathIdentifier: FilterPathIdentifiers.PERSON,
        propertyLabel: t('terms:person', { count: T_MANY }),
        typePropertyName: FilterTypes.PERSON,
      },
      {
        apiUrl: '/api/v1/thing',
        attributes: {
          typeCache: thingTypesCache,
          typePath: FilterTypes.THING_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.select-a-thing'),
        pathIdentifier: FilterPathIdentifiers.THING,
        propertyLabel: t('terms:thing', { count: T_MANY }),
        typePropertyName: FilterTypes.THING,
      },
      {
        dataType: FilterDataTypes.CACHE,
        entityCache: groupCache,
        fieldLabel: t('common:component.filter.labels.select-a-group'),
        pathIdentifier: FilterPathIdentifiers.GROUP,
        propertyLabel: t('common:terms.group', { count: T_MANY }),
        typePropertyName: FilterTypes.GROUP,
      },
    ];

    return (
      <NewFilter
        filterFields={filterFields}
        filters={filters}
        onCloseClicked={() => setFilterOpen(false)}
        onFiltersChanged={onFiltersChanged}
        savedFilterKey={SAVED_FILTER_KEY}
        storageKey="alerts"
        data-testid="alerts-new-filter"
      />
    );
  }, [alertTypes]);

  if (groupsError) return <ErrorPage error={groupsError} />;
  if (personTypesError) return <ErrorPage error={personTypesError} />;
  if (thingTypesError) return <ErrorPage error={thingTypesError} />;
  if (myFeaturesError) return <ErrorPage error={myFeaturesError} />;
  if (featuresError) return <ErrorPage error={featuresError} />;

  if ([personTypesState, thingTypesState, groupsState, myFeaturesState, featuresState].includes('pending') || !moduleLoaded) {
    return <MiddleSpinner />;
  }

  return (
    <AlertListPage
      actions={actions}
      data-testid='alert-list'
      dateOptions={dateOptions}
      defaultDateRange={{ startTime: initialStartTime, endTime: initialEndTime }}
      defaultDateLabel={t('common:component.date-time-range.labels.last-eight-hours')}
      entityTypes={{
        alerts: alertTypes ?? [],
        group: groups ? entityGroup(groups) : [],
        persons: [],
        personTypes: personTypes ?? [],
        things: [],
        thingTypes: thingTypes ?? [],
      }}
      icon={module?.AlertIcon && <module.AlertIcon />}
      onQueryChanged={findAlerts}
      onRefresh={refresh}
      renderContent={renderContent}
      renderFilterContent={useNewFilter ? renderFilterContent : undefined}
      setLastRefresh={setLastRefresh}
      setRefresh={setRefresh}
      title={t('common:terms.alert', { count: T_MANY })}
      useNewFilter={useNewFilter}
    />
  );
};
